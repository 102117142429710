import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { APP_STYLES, AnyObject, DEFAULT_STYLES, urlToParams } from '../../../utils/basic';
import RingCustomizerProvider from '../../context/RingCustomizerContext';

interface IRingCustomizerHOC {
  type: number;
  ring_url?: string;
  otherQueryParams?: AnyObject;
}

export interface IParams {
  pathParams: { subpath?: string; [key: string]: any };
  queryParams: AnyObject;
  otherQueryParams?: AnyObject;
}

export interface IUrl {
  pathString: string;
  queryString: string;
  ring_url: string;
}

const RingCustomizerHOC = (Component: FC) => {
  return function HOC({ type, ring_url, otherQueryParams }: IRingCustomizerHOC) {
    const [url, setUrl] = useState<IUrl>({
      pathString: '',
      queryString: '',
      ring_url: ''
    })
    const [params, setParams] = useState<IParams>({
      pathParams: {},
      queryParams: {}
    });

    const location = useLocation();

    useEffect(() => {
      /**
       * Handle Resize using window listner
       */

      function handleResize() {
        let vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
      window.addEventListener('resize', handleResize)
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    useEffect(() => {
      if (params.pathParams?.subpath) {
        const styles = APP_STYLES[params.pathParams?.subpath] || DEFAULT_STYLES;

        document.documentElement.style.setProperty('--primary-font-family', styles.primaryFont);
        document.documentElement.style.setProperty('--secondary-font-family', styles.secondaryFont);

        document.documentElement.style.setProperty('--brand-color', styles.brandColor);
        document.documentElement.style.setProperty('--brand-logo', styles.brandlogo);

        document.documentElement.style.setProperty('--primary-color', styles.primaryColor);
        document.documentElement.style.setProperty('--secondary-color', styles.secondaryColor);
        document.documentElement.style.setProperty('--tertiary-color', styles.tertiaryColor);

        document.documentElement.style.setProperty('--primary-bg-color', styles.primaryBgColor);
        document.documentElement.style.setProperty('--secondary-bg-color', styles.secondaryBgColor);


        document.documentElement.style.setProperty('--primary-border-color', styles.primaryBorderColor);
        document.documentElement.style.setProperty('--secondary-border-color', styles.secondaryBorderColor);

        document.documentElement.style.setProperty('--primary-border-radius', styles.primaryBorderRadius);
        document.documentElement.style.setProperty('--secondary-border-radius', styles.secondaryBorderRadius);
        document.documentElement.style.setProperty('--tertiary-border-radius', styles.tertiaryBorderRadius);

        document.documentElement.style.setProperty('--rc-box-tab-case', styles.rcBoxTabCase);

        document.documentElement.style.setProperty('--sidebar-order', `${styles.sidebarOrder}`);
      }
    }, [params.pathParams?.subpath])


    useEffect(() => {
      const URL = ring_url || `${location.pathname}${location.search}`;
      if(URL) {
        const { pathParams, queryParams: searchParams } = urlToParams(URL);
        /**
         * From queryParams seperate 'utm_' & product_id into otherQueryParams
         */
        let { product_id, ...queryParams } = searchParams;
        const utmQueryParams = Object.fromEntries(Object.entries(queryParams).filter(([key]) => key.includes('utm_') ));
        queryParams = Object.fromEntries(Object.entries(queryParams).filter(([key]) => !key.includes('utm_') ));

        setParams({
          pathParams,
          queryParams,
          otherQueryParams: { product_id, ...utmQueryParams, ...otherQueryParams }
        });
        if (type === 1) {
          const { profile, width, ring_materials } = pathParams;
          const pathString = `/rings/profiles/${profile}/widths/${width}/ring_materials/${ring_materials}`;
          const queryString = new URLSearchParams(queryParams).toString();
          setUrl({
            pathString,
            queryString,
            ring_url: `${pathString}?${queryString}`
          });
        } else if (type === 2) {
          const { styles, ring_materials, color_arrangements, stone_quantities, variants } = pathParams;
          const pathString = `/women/rings/styles/${styles}/ring_materials/${ring_materials}/color_arrangements/${color_arrangements}/stone_quantities/${stone_quantities}/variants/${variants}`;
          const queryString = new URLSearchParams(queryParams).toString();
          setUrl({
            pathString,
            queryString,
            ring_url: `${pathString}?${queryString}`
          });
        } else if (type === 3) {
          const { ring_type, ring_materials } = pathParams;
          const pathString = `/rings/${ring_type}/ring_materials/${ring_materials}`;
          const queryString = new URLSearchParams(queryParams).toString();
          setUrl({
            pathString: pathString,
            queryString,
            ring_url: `${pathString}?${queryString}`
          });
        }
      }

    }, [location, ring_url, type, otherQueryParams]);

    return (
    <RingCustomizerProvider params={params} url={url} type={type} subpath={params.pathParams.subpath || ''} isQueryHandling={!!ring_url}>
      <Component />
    </RingCustomizerProvider>)
  }
}

export default RingCustomizerHOC;