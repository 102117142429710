import { useEffect, useState } from "react";
import Modal from "react-modal";
import { isEmpty } from "../../../utils/basic";
import { ICategoryDesc } from "../../types/customizer";
import Rating from "../Rating";
import If from "../If";
import MaterialInfo from "./MaterialInfo";
import closeIcon from '../../../assets/images/close-icon.svg';
import rightArrow from '../../../assets/images/right-chevrolet.svg';

export type IGroupMaterialInfo = {
  isOpen: boolean;
  onClose: () => void;
  descriptions: Array<ICategoryDesc>
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    zIndex: 100,
    transform: "translate(-50%, -50%)",
  },
};

const GroupMaterialInfo = ({ isOpen, onClose, descriptions }: IGroupMaterialInfo) => {
  const [listView, showListView] = useState<boolean>(true);
  const [item, setItem] = useState<null | ICategoryDesc>(null);

  useEffect(() => {
    if(isOpen) showListView(true);
  }, [isOpen])

  const openIndividualView = (item: ICategoryDesc) => () => {
    setItem(item);
    showListView(false);
  }

  const closeIndividualView = () => {
    showListView(true);
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="top-0 bottom-0 left-0 right-0 m-auto xs:w-[350px] sm:w-[500px] min-h-[400px] bg-secondary-bg-color absolute p-5"
      style={customStyles}
      overlayClassName="bg-[#999c9fab] fixed inset-0 z-10"
    >
      <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" onClick={onClose}/>
      <If condition={listView}>
        <>
          <h2 className="primary-font-family tw-text-lg tw-font-semibold tw-text-primary-color tw-my-4">Select a Material to Learn More</h2>
          <section className="max-h-[450px] overflow-auto pr-3 secondary-font-family text-secondary-color">
            {descriptions.map((item, index) => (
              <div className="tw-flex tw-items-center tw-justify-between tw-mb-4 tw-cursor-pointer" onClick={openIndividualView(item)} key={index}>
                <section className="xs:w-5/6 sm:w-4/6 text-right">
                  <h2 className="tw-text-base tw-font-medium tw-mb-2 tw-text-primary-color">{item.name}</h2>
                  <div className="tw-flex tw-items-center tw-justify-end">
                    <label className="tw-text-xs">Weight:</label>
                    {isEmpty(item.weight) ? <div className="tw-font-bold tw-text-xs tw-ml-1">N/A</div> : <Rating count={Number(item.weight)} />}
                  </div>
                  <div className="tw-flex tw-items-center tw-justify-end">
                    <label className="tw-text-xs">Scratch Resistance:</label>
                    {isEmpty(item.scratch_resistance) ? <div className="tw-font-bold tw-text-xs tw-ml-1">N/A</div> :
                      <Rating count={Number(item.scratch_resistance)} />}
                  </div>
                </section>
                <section className="tw-ml-1">
                  <img src={`${process.env.REACT_APP_ASSET_URL}${item.swatch_url}}`} alt="swatch_url"
                    width={70} height={70} className="tw-rounded-full"/>
                </section>
                <section className="tw-ml-1 tw-cursor-pointer">
                  <img src={rightArrow} alt="close_icon" className="tw-w-6 tw-h-6" />
                </section>
              </div>))}
          </section>
        </>
      </If>
      <If condition={!listView}>
        {item && <MaterialInfo {...item} onBack={closeIndividualView}/>}
      </If>
    </Modal>
  )
}

export default GroupMaterialInfo;
