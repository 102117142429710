import React, { useEffect, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { AnyObject } from '../../../utils/basic';
import { MENS_PATHS, WOMENS_PATHS } from '../../../routes';
import { stat } from 'fs';
import RingCustomizer from '../RingCustomizer';
import NoMatch from '../NoMatch/NoMatch';

interface IState {
  type: number;
  ring_url: string;
  otherQueryParams: AnyObject;
}

const URLScreening = () => {
  const [state, setState] = useState<IState>({
    type: 1,
    ring_url: '',
    otherQueryParams: {}
  });
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const { ring_url, ...otherQueryParams } = Object.fromEntries(searchParams.entries());
    if(ring_url) {
      const isRingType1 = matchRoutes(MENS_PATHS.map(path => ({ path })), ring_url);
      const isRingType2 = matchRoutes(WOMENS_PATHS.map(path => ({path})), ring_url);

      if(isRingType1) {
        setState({
          type: 1,
          ring_url,
          otherQueryParams
        })
      } else if(isRingType2) {
        setState({
          type: 2,
          ring_url,
          otherQueryParams
        })
      }
    }
  }, [location]);
  
  if(state.ring_url && state.type) {
    return <RingCustomizer {...state} />
  }

  return (<NoMatch/>)
}

export default URLScreening;