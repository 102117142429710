import { AnyObject } from "../../../utils/basic";
import { getV1PageData } from "../../../utils/v1";
import { getV2PageData } from "../../../utils/v2";
import { getV3PageData } from "../../../utils/v3";

export type IPageData = {
    product: any,
    type: number,
    ring_type: string;
    subpath: string;
    storeData: AnyObject | null;
}

export const getPageData = (props: IPageData) => {
    const { type } = props;
    if(type === 1) {
        return getV1PageData(props);
    } else if (type === 2) {
        return getV2PageData(props);
    } else if (type === 3) {
        return getV3PageData(props);
    }
    return [];
};
