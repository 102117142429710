
type IProps = {
  condition: boolean | number | string | null | undefined;
  children: React.ReactNode
}

const If = (props: IProps) => {
  if (props.condition) {
      return <>{props.children}</>;
  }
  return <></>;
};

export default If;
