import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store";
import { AnyObject } from "../../../utils/basic";

export interface CustomizerState {
  data: null | AnyObject
}

const initialState: CustomizerState = {
  data: null
}

export const customizerSlice = createSlice({
  name: 'customizer',
  initialState: initialState,
  reducers: {
    setProductData: (state: CustomizerState, action: PayloadAction<object>) => {
      state.data = action.payload
    }
  }
});

export const { setProductData } = customizerSlice.actions;

const customizerReducer = customizerSlice.reducer;

export const selectProduct = (state: RootState) => {
  return state.customizer.data
}

export default customizerReducer
