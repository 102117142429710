import { ICategoryDesc } from "../../types/customizer";
import Rating from "../Rating";
import clsx from "clsx";

import leftArrow from '../../../assets/images/left-chevrolet.svg';
// import rightArrow from '../../../assets/images/right-chevrolet.svg';
import If from "../If";
import { APP_STYLES, DEFAULT_STYLES, isEmpty } from "../../../utils/basic";
import { useRingCustomizerContext } from "../../context/RingCustomizerContext";

interface IMaterialInfo extends ICategoryDesc {
	onBack?: () => void
}

const MaterialInfo = (props: IMaterialInfo) => {
	const {subpath} = useRingCustomizerContext();

	const openLearnMore = () => {
		window.open(`https://wearemblem.com${props.link || '/pages/material'}/`, '_blank');
	}

	const styles = APP_STYLES[subpath] || DEFAULT_STYLES;
  return (
		<div>
			<If condition={!!props.onBack}>
				<div className="tw-inline-flex tw-items-center tw-cursor-pointer" onClick={props.onBack}>
					<img src={leftArrow} alt="left_arrow" className="tw-mr-1"/>
					<button>Back</button>
				</div>
			</If>
			<div className="tw-px-6 tw-pt-4 tw-font-bold tw-text-xl tw-mb-4 primary-font-family tw-text-primary-color">{props.name}</div>
			<div className="tw-max-h-[550px] tw-overflow-auto secondary-font-family tw-text-secondary-color">
				<div className="tw-px-6 tw-pb-4">
					<p className="tw-text-gray-700 tw-text-sm tw-leading-6">
						{props.short_desc}
					</p>
					<button className={clsx("tw-btn tw-font-bold tw-border-b tw-text-sm", {'tw-hidden': !styles.showLearnMore})} onClick={openLearnMore}>
						Learn more &rarr;
					</button>
				</div>
				<div className="tw-px-6 tw-pt-4 tw-pb-2">
				<div className="tw-flex tw-items-center tw-justify-end">
					<label className="tw-text-xs">Weight:</label>
					{isEmpty(props.weight) ? <div className="tw-font-bold tw-text-sm tw-ml-1">N/A</div> : <Rating count={Number(props.weight)} />}
				</div>
				<div className="tw-flex tw-items-center tw-justify-end">
					<label className="tw-text-xs">Scratch Resistance:</label>
					{isEmpty(props.scratch_resistance) ? <div className="tw-font-bold tw-text-sm tw-ml-1">N/A</div> : 
						<Rating count={Number(props.scratch_resistance)} />}
				</div>
				</div>
				<div className="tw-flex tw-justify-center tw-items-end tw-min-h-[150px] tw-max-h-[240px] tw-pr-3">
					<img src={props.image_url ||'https://assets.staging.lashbrookdesigns.com/rings/profiles/D/widths/7/ring_materials/14KW/images/1?ring_finish=POLISH&finish_type=F&weight=P&size=10&feature_group=N&image_size=250'} alt="Mountain" />
				</div>
			</div>
		</div>
  );
};

export default MaterialInfo;
