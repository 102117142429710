/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Tooltip } from 'reactstrap';
import Slider from 'react-rangeslider';
import queryString from 'qs';
import clsx from 'clsx';
import Accordion from '../../components/Accordion';
import Loading from '../../components/Loader';
import If from '../../components/If';
import Summary from '../../components/Summary';
import { ITab } from '../../types/customizer';
import { getSelectedOption } from '../../../utils/commonRingUtils';
import { getPageData } from '../RingCustomizer/Pages';
import GuaranteedIcon from '../../../assets/images/Guaranteed.svg';
import MadeInIcon from '../../../assets/images/made.svg';
import SizingIcon from '../../../assets/images/sizing.svg';
import ComfortIcon from '../../../assets/images/comfort.svg';
import CustomizeIcon from '../../../assets/images/customize-icon.svg';
import MyGallery from '../../components/MyGallery';
import './index.scss';
import { useRingCustomizerContext } from '../../context/RingCustomizerContext';
import { APP_STYLES, DEFAULT_STYLES } from '../../../utils/basic';
import { setProductData } from '../../../redux/slices/customizer/customizerSlice';
import { useAppDispatch } from '../../../redux/hooks';

interface IProps {
  type: number;
}

const ProductDetail = ({ type }: IProps) => {
    const [url, setUrl] = useState<string>('');
    const [selectedSize, setSelectedSize] = useState('');
    const [rangeValue, setRangeValue] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [isSizeUpdating, setIsSizeUpdating] = useState(false);
    const [pages, setPages] = useState<ITab[]>([]);

    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { handleAddToCart, subpath } = useRingCustomizerContext();

    const { data: product, isFetching, refetch } = useQuery(['PRODUCT_INFO', url], async () =>  {
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/${type === 1 ? 'v1': 'v2'}${url}`).then(async res => {
          if(res.ok) {
            return res.json()
          } else {
            const text = await res.text()
            throw new Error(text);
          }
        })
      }, {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false
      });

    useEffect(() => {
        const { search: searchStr } = location;
        let path:string;
        if (type === 1) {
            const { profile, width, ring_materials } = params;
            path = `/rings/profiles/${profile}/widths/${width}/ring_materials/${ring_materials}${searchStr}`;
        } else if (type === 2) {
            const { styles, ring_materials, color_arrangements, stone_quantities, variants } = params;
            path = `/women/rings/styles/${styles}/ring_materials/${ring_materials}/color_arrangements/${color_arrangements}/stone_quantities/${stone_quantities}/variants/${variants}${searchStr}`;
        } 
        setUrl(path);
    }, [params, location.search]);
    
    useEffect(() => {
        url && refetch();
      }, [url, refetch])

    useEffect(() => {
        if (!isFetching && isFetching !== isSizeUpdating) {
            setIsSizeUpdating(false);
        }
    }, [isFetching]);

    useEffect(() => {
        if (!product) {
            return;
        }
        dispatch(setProductData(product));
        if (type === 1 || type === 2) {
            const tabData = getPageData(product, subpath);
            setPages(tabData);

            const selectedOption = getSelectedOption(product?.size);
            if (selectedOption) {
                const size = parseFloat(selectedOption.sku);
                setRangeValue(size);
                setSelectedSize(size);
            }
        }
    }, [product]);


    const getMinRange = (list) => list && list.length > 0 ? parseFloat(list[0].sku) : 3;

    const getMaxRange = (list) =>
        list && list.length > 0 ? parseFloat(list[list.length - 1].sku) : 24;

    const getStep = (list) =>
        list && list.length > 0 ? parseFloat(list[0].sku) - parseFloat(list[1].sku) : 0.25;

    const sizePlus = () => {
        let newValue;
        if (rangeValue >= 3 && rangeValue < 24) {
            newValue = rangeValue + 0.25;
            handleSizeChange(newValue);
            setRangeValue(newValue);
        } else {
            return null;
        }
    };

    const sizeMinus = () => {
        let newValue;
        if (rangeValue > 3 && rangeValue <= 24) {
            newValue = rangeValue - 0.25;
            handleSizeChange(newValue);
            setRangeValue(newValue);
        } else {
            return null;
        }
    };

    const handleSizeChange = (newSize) => {
        setIsSizeUpdating(true);
        let { search: searchStr } = location;
        const searchParams = new URLSearchParams(searchStr || '');
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        searchParamsObject.size = newSize || rangeValue;
        searchStr = queryString.stringify(searchParamsObject);

        let path = '';
        if (type === 1) {
            const { profile, width, ring_materials } = params;
            path = `/rings/profiles/${profile}/widths/${width}/ring_materials/${ring_materials}?${searchStr}`;
        } else if (type === 2) {
            const { styles, ring_materials, color_arrangements, stone_quantities, variants } = params;
            path = `/women/rings/styles/${styles}/ring_materials/${ring_materials}/color_arrangements/${color_arrangements}/stone_quantities/${stone_quantities}/variants/${variants}?${searchStr}`;
        } else if (type === 3) {
            const { profile, width, ring_material } = params;
            path = `/static-product/profiles/${profile}/widths/${width}/ring_materials/${ring_material}?${searchStr}`;
        }
        navigateToUrl(`/shop/product${path}`,true);
    };

    const handleRedirect = () => {
        navigateToUrl(product.ring_options_url,false)
    };

    const navigateToUrl = (url: string, replace = true) => {
        const { pathprefix, subpath } = params;
        let navigateUrl = url;
        if(pathprefix && subpath) {
          navigateUrl = `/${pathprefix}/${subpath}${url}`;
        } else if(subpath){
          navigateUrl = `/${subpath}${url}`;
        }
        navigate(navigateUrl, { replace: replace }); // After url is navigated it calls a useffect of location dependencies
      }
    
	const styles = APP_STYLES[subpath] || DEFAULT_STYLES;
    return (
        <>
            <Loading timedOut={isFetching} />
            <div className="tw-w-3/4">
				<div className={clsx("lg:tw-h-8 tw-h-6 tw-w-full tw-m-2", { 'tw-hidden': styles.showTopBar })}>
        	        <a href='tw-/' className="tw-w-56 tw-inline-block">
        				<img className="brand-logo" alt="brand_logo"/>
                    </a>
				</div>
			</div>
            <div id="product_details_container" className="product_details_container neue-haas-unica-pro tw-text-blue-900 sm:tw-pt-3 tw-flex-1 tw-overflow-y-auto">
                <div id="product-container" className="tw-py-9 lg:tw-py-10 xl:tw-w-4/5 lg:tw-w-5/6 sm:tw-w-11/12 tw-mx-auto">
                    <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-start tw-gap-8 lg:tw-gap-0 xl:tw-gap-24 lg:tw-mx-9">
                        {/* Gallery */}
                        <div className="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-relative tw-px-7 lg:tw-px-0">
                            <div className="tw-flex-1 tw-w-full tw-mb-8 tw-min-h-[240px] md:tw-min-h-[300px] tw-relative">
                                <MyGallery images={product?.images || (product?.image_url ? [product?.image_url] : [])} showNav goFullscreen={false} />
                            </div>
                            <If condition={type !== 3}>
                                <button className="customizer-btn tw-font-[inherit]" onClick={handleRedirect}>
                                    <img src={CustomizeIcon} alt="tw-" />
                                    <span>Customize with Builder</span>
                                </button>
                            </If>
                        </div>
                        {/* Details */}
                        <div className="tw-flex-1">
                            <div className="tw-bg-blue-50 tw-text-blue-900 tw-px-8 xl:tw-px-13 tw-py-9 lg:tw-rounded-[10px]">
                                <h1 className="tw-text-2xl tw-mb-8 tw-reckless xs:tw-hidden lg:tw-block">{product?.title} - {product?.price}</h1>
                                <h1 className="tw-text-2xl tw-mb-3 tw-reckless lg:tw-hidden tw-pb-3 tw-border-transparent tw-border-b-gold tw-border-solid">{product?.title}</h1>

                                <div className="tw-description tw-text-blue-900">
                                    <h2 className="tw-font-bold tw-text-base tw-mb-3 lg:tw-hidden">Details</h2>
                                    <p className="tw-mb-0">{product?.description}</p>
                                    <p className="tw-text-dark-grey tw-text-xs tw-mb-3">{product?.sku}</p>
                                </div>

                                <Accordion
                                    title="SUMMARY"
                                    content={<Summary tabs={pages} isPDP />}
                                    IsFontInherit
                                    isJXSContent
                                    containerClass="pdp-summary-accordion"
                                    iconPosition="right"
                                />

                                <div className="tw-ring-size">
                                    <p className="tw-text-blue-900 tw-font-bold tw-uppercase tw-mb-4">
                                        {'Estimate your Size :'}
                                        <strong className="tw-title-text-color tw-pl-2">
                                            {selectedSize}
                                        </strong>
                                    
                                            <>
                                                <i className="fa fa-info-circle tw-ml-2" aria-hidden="true" id="size_info" />
                                                <Tooltip
                                                    className="size-info-tooltip"
                                                    placement="bottom"
                                                    isOpen={tooltipOpen}
                                                    target="size_info"
                                                    toggle={() => setTooltipOpen(!tooltipOpen)}
                                                >
                                                    Don't worry, we'll ship you a sizing kit before we begin making your ring.
                                                </Tooltip>
                                            </>
                                    
                                    </p>
                                    <div className={clsx('slider__container', { loading: isFetching && isSizeUpdating })}>
                                        <span className="decrement">
                                            <i
                                                className={`fa fa-minus ${rangeValue <= 3 ? 'disabled' :''}`}
                                                aria-hidden="true"
                                                onClick={sizeMinus}
                                            />
                                        </span>
                                        <Slider
                                            min={getMinRange(product?.size)}
                                            max={getMaxRange(product?.size)}
                                            step={getStep(product?.size)}
                                            value={rangeValue}
                                            onChange={setRangeValue}
                                            onChangeComplete={() => handleSizeChange()}
                                            className="slider__range"
                                        />
                                        <span className="increment">
                                            <i
                                                className={`fa fa-plus ${rangeValue >= 24 ? 'disabled' : ''}`}
                                                aria-hidden="true"
                                                onClick={sizePlus}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="cta-container">
                                    <button className="tw-bg-blue-500 hover:tw-bg-blue-500 tw-text-white hover:tw-text-white tw-w-full tw-px-9 tw-py-2 tw-rounded-[10px] tw-font-bold tw-normal-case lg:tw-mb-4 focus:tw-border-none focus:tw-shadow-none focus:tw-outline-none tw-h-14 xs:tw-hidden lg:tw-inline-block tw-font-[inherit]" onClick={handleAddToCart}>Add to Cart</button>
                                    <button className="tw-bg-blue-500 hover:tw-bg-blue-500 tw-text-white hover:tw-text-white tw-w-full tw-px-9 tw-py-2 tw-rounded-[10px] tw-font-bold tw-normal-case lg:tw-mb-4 focus:tw-border-none focus:tw-shadow-none focus:tw-outline-none tw-h-14 lg:tw-hidden tw-mb-4 tw-font-[inherit]" onClick={handleAddToCart}>Add to Cart - {product?.price}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Info Icons */}
                <div id="tw-info-icons-container" className="xs:tw-pt-0 tw-py-9 lg:tw-py-10 xl:tw-w-4/5 lg:tw-w-5/6 sm:tw-w-11/12 tw-mx-auto" >
                    <div className="tw-flex tw-flex-wrap tw-justify-between tw-mx-9">
                        <div
                            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-center tw-my-9 tw-w-2/4 md:tw-w-auto"
                        >
                            <img src={GuaranteedIcon} alt="tw-guarantee_for_life" className="tw-h-14" />
                            <div className="tw-text-base lg:tw-text-lg tw-text-blue-900">Lifetime Warranty</div>
                        </div>
                        <div
                            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-center tw-my-9 tw-w-2/4 md:tw-w-auto"
                        >
                            <img src={MadeInIcon} alt="tw-handcraft_here" className="tw-h-14" />
                            <div className="tw-text-base lg:tw-text-lg tw-text-blue-900">Handcrafted Here</div>
                        </div>
                        <div
                            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-center tw-my-9 tw-w-2/4 md:tw-w-auto"
                        >
                            <img src={SizingIcon} alt="tw-sizing_life" className="tw-h-14" />
                            <div className="tw-text-base lg:tw-text-lg tw-text-blue-900">Sizing for Life</div>
                        </div>
                        <div
                            className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-center tw-my-9 tw-w-2/4 md:tw-w-auto"
                        >
                            <img src={ComfortIcon} alt="tw-genuine tw-material" className="tw-h-14" />
                            <div className="tw-text-base lg:tw-text-lg tw-text-blue-900">Genuine Materials</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetail;
