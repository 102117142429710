import React from 'react';
import loader from '../../../assets/images/loader2.gif';
import loaderInv from '../../../assets/images/loader2inv.gif';
import './loaderStyles.css';

type ILoadingWrapper = {
  children: React.ReactNode;
  isInline?: boolean,
};

const LoadingWrapper = ({ children, isInline }: ILoadingWrapper) => (
  <div className={isInline ? 'inline-loading-container-wrapper' : 'loading-container-wrapper'}>
    <div className={isInline ? 'inline-loading-container' : 'loading-container'}>{children}</div>
  </div>
);

type ILoader = {
  error?: boolean,
  message?: string,
  timedOut?: boolean,
  pastDelay?: boolean,
  isInline?: boolean,
};

const Loader = (props: ILoader) => {
  let loaderImage = loader;
  if (props.isInline) {
    loaderImage = loaderInv;
  }
  if (props.error) {
    return (
      <LoadingWrapper isInline={props.isInline}>
        <p>Something went wrong!</p>
      </LoadingWrapper>
    );
  }
  if (props.timedOut) {
    return (
      <LoadingWrapper isInline={props.isInline}>
        <div className={props.isInline ? 'text-center' : 'text-center mt-5'}>
          {props.message && (<p>{props.message}</p>)}
          <img src={loaderImage} alt="logo" />
        </div>
      </LoadingWrapper>
    );
  }
  if (props.pastDelay) {
    return (
      <LoadingWrapper isInline={props.isInline}>
        <span>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </span>
      </LoadingWrapper>
    );
  }
  return null;
};

export default Loader;
