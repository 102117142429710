import clsx from 'clsx';
import React, { type MouseEventHandler } from 'react';
import { type ITabs } from '../../types/customizer';
// import listIcon from '../../../assets/images/list-icon.svg';
// import rightArrowIcon from '../../../assets/images/right-arrow.svg';
import closeIcon from '../../../assets/images/close-icon.svg';
import './index.scss';
import { twMerge } from 'tailwind-merge';
import { FONT_MAP, getSelectedInfo } from '../../../utils/commonRingUtils';
import If from '../If';

interface Props {
  pages: ITabs
  selectedTab: string
  handleActiveTab: (a: any, b: any) => MouseEventHandler<HTMLDivElement> | undefined
  toggleOpen: React.MouseEventHandler<HTMLDivElement>
  price?: string
}

const MobileSidebar = ({ pages, selectedTab, handleActiveTab, toggleOpen, price }: Props) => {
  const getUrl = (swatch_url: string) => {
		return swatch_url.indexOf('http') !== -1 ? swatch_url : `${process.env.REACT_APP_ASSET_URL}${swatch_url}`
  }

  return ( // Hide for sm devices
    <div className="mobile-sidebar">
			{pages.map(item => {
				const selectedInfo = getSelectedInfo(item);
				const selectedText = selectedInfo?.text || '';
				return (
				<div role="presentation" key={item?.eventKey} className={clsx(`sidebar-tab`, { 'tw-bg-primary-bg-color': selectedTab === item.eventKey })}
					onClick={handleActiveTab(item.eventKey, true)}>
					<div className="tw-flex tw-items-start tw-justify-center tw-flex-col tw-ml-[22%]">
						{/* Sidebar title starts */}
						<div
							className={'tw-relative tw-font-medium sidebar-title primary-font-family'}>
							{selectedTab === item.eventKey ? <i className="fa fa-arrow-right arrow-icon" aria-hidden="true"></i> : null}
							{typeof item.title === 'string' && item.title}
							{typeof item.title === 'function' && item.title()}
						</div>
						{/* Sidebar title ends */}

						{/* Sub content starts */}
						<div>
							{item?.disclaimer &&  <div className="tw-text-xs tw-mt-2 tw-text-blue-900 tw-text-opacity-80">{item?.disclaimer}</div>}
							{/* For engraving alone */}
							<If condition={item.eventKey === 'engraving' && item.selectedItem === 'text'}>
									<div className={twMerge('tw-text-xs tw-mt-2', FONT_MAP[selectedText || ''])}>
									{selectedInfo?.engravingText || '(none)'}
								</div>
							</If>
							{/* For outside and 2nd outside alone */}
							{item.selectedFeature &&  <div className="tw-text-xs tw-mt-2">{item.selectedFeature}</div>}
						</div>
						{/* Sub content ends */}
					</div>
					<div className={clsx('tw-flex tw-justify-center tw-items-center tw-flex-col tw-w-24 tw-py-1', { 'tw-relative': selectedTab === item.eventKey })}>
						{selectedInfo?.url && !(/^None$/i).test(selectedText) &&
						<img className="tw-rounded-full tw-h-5 tw-w-5 tw-mb-1" alt="img" src={`${getUrl(selectedInfo?.url)}`} />}
						{selectedText && <div className="tw-text-xs tw-text-center sidebar-value">{selectedText}</div>}
						<If condition={item.eventKey === 'summary'}>
							<div className='tw-text-xs tw-text-center sidebar-value'>
								{price}
							</div>
						</If>
					</div>
				</div>)})
			}
			<div role="presentation" className="tw-absolute tw-left-3 tw-top-3 tw-cursor-pointer" onClick={toggleOpen}>
				<img src={closeIcon} alt="list_icon" className="tw-inline" />
			</div>
    </div>
	)
};

export default MobileSidebar;
