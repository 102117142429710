import React from 'react';
import Modal from 'react-modal';
import closeIcon from '../../../assets/images/close-icon.svg';
import './index.scss';

export interface IAppModal {
  isOpen: boolean;
  onClose: (e: any) => void;
  hideCloseIcon?: boolean;
  size?: 'sm' | 'md' | 'lg';
  children?: React.ReactNode;
}

const AppModal = ({ isOpen, onClose, size = 'md', children, hideCloseIcon = false }: IAppModal) => {

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={`modalOverlay`}
      className={`modalContent ${size}`}
    >
      {!hideCloseIcon && <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer" role="presentation" onClick={onClose} />}
      {children}
    </Modal>
  );

}

export default AppModal;