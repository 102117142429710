import React from 'react';
import If from '../If';
import { Helmet } from 'react-helmet';
import { APP_STYLES, DEFAULT_STYLES } from '../../../utils/basic';

const AppHTMLTag = ({ subpath }: any) => {
  const styles = APP_STYLES[subpath] || DEFAULT_STYLES;
  return (<>
    <If condition={['customize'].includes(subpath)}>
      <Helmet>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
        <title>Emblem | Personalized, Handmade Wedding Rings</title>
        {/* klaviyo tags for general and viewed product */}
        <script async src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Wd4NFp"></script>

        <script async src="https://asset.fwcdn3.com/js/live-helper.js" data-fwparam_chat_channel_id="no8bvX"></script>
        <script>
          {`function showWidget() {
            window._fwn.liveHelper.actions.showWidget()
          }
          if (window._fwn?.liveHelper) {
            // _fwn is available, use the API immediately
            showWidget()
          } else {
            // Wait for fw:ready event
            document.addEventListener('fw:ready', ()=>{
              showWidget()
            });
          }`}
        </script>
      </Helmet>
    </If>
    <If condition={!['customize'].includes(subpath)}>
      <Helmet>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/${styles.tabIcon}`} />
        <title>{styles.tabTitle || `${subpath?.toUpperCase()} | Personalized, Handmade Wedding Rings`}</title>
      </Helmet>
    </If>
  </>)
}

export default AppHTMLTag;