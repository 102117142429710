import clsx from "clsx";

interface IRating {
  count: number;
}

const Rating = ({ count }: IRating) => {
  return (
    <div className="tw-ml-1">
      <span  className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1', {'tw-bg-black': 1 <= count})} />
      <span  className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1', {'tw-bg-black': 2 <= count})} />
      <span  className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1', {'tw-bg-black': 3 <= count})} />
      <span  className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1', {'tw-bg-black': 4 <= count})} />
      <span  className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1', {'tw-bg-black': 5 <= count})} />
    </div>
  )
}

export default Rating;