import { useEffect, useRef, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import loaderInv from '../../../assets/images/loader2inv.gif';
import Cancel from '../../../assets/images/close_icon@3x.png';
import If from '../If';
// import ZoomIn from '../../../assets/images/zoom-in.svg';
// import ZoomOut from '../../../assets/images/zoom-out.svg';

import './myGalleryStyles.css';
import { APP_STYLES, DEFAULT_STYLES } from '../../../utils/basic';
import { useRingCustomizerContext } from '../../context/RingCustomizerContext';

type IProps = {
  images: string[];
  showNav?:boolean;
  goFullscreen?:boolean;
}

const MyGallery = ({ images, showNav = false, goFullscreen = true }: IProps) => {
  const [data, setData] = useState<ReactImageGalleryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const {subpath} = useRingCustomizerContext();
  const ref = useRef<any>(null)

  useEffect(() => {
    const imageList = images.map(item => {
      const styles = APP_STYLES[subpath] || DEFAULT_STYLES;
      if(!styles.watermarkBrand) {
        item += '&watermark=false'
      }
      item = item.replace('&watermarkBrand=emblem', '');
      if(styles.watermarkBrand) {
        const watermarkBrand = styles.watermarkBrand;
        item += `&watermarkBrand=${watermarkBrand}`;
      }
      const imageStr =  `${process.env.REACT_APP_ASSET_URL}${item}`
        return ({
          original: `${imageStr}&image_size=1200&rand=${Math.random().toString(12)}`,
          thumbnail: `${imageStr}&image_size=90&rand=${Math.random().toString(12)}`
        });
      });
      setData(imageList)
      if (imageList.length) {
        setLoading(true);
      }
  }, [images]);

  const hideLoader = () => {
    setLoading(false);
  }

  const openFullScreen = () => {
    if(!fullScreen) {
      setFullScreen(true);
      ref.current.fullScreen();
    }
  };

  const closeFullScreen = () => {
    setFullScreen(false);
    ref.current.exitFullScreen();
  };

  const renderItem = (item: ReactImageGalleryItem) => {
    if(fullScreen) {
      return (
        <PinchZoomPan maxScale={3} zoomButtons={fullScreen} position="center">
          <img
            src={item.original}
            className='image-gallery-image'
            alt={item.originalAlt}
            srcSet={item.srcSet}
            sizes={item.sizes}
            onLoad={hideLoader}
          />
        </PinchZoomPan>
      );
    } else {
      return (<img
        src={item.original}
        className='image-gallery-image'
        alt={item.originalAlt}
        srcSet={item.srcSet}
        sizes={item.sizes}
        onLoad={hideLoader}
      />);
    }
  };

  const renderFullScreenButton = () => (
    <img src={Cancel} alt="close_icon" onClick={closeFullScreen} role="presentation"
      className="tw-absolute tw-top-3 tw-right-3 tw-w-9 tw-h-9 tw-cursor-pointer"/>
  );

  return (
    <>
      <If condition={loading}>
        <div className="loading-mask" style={{ backgroundImage: `url(${loaderInv})` }} />
      </If>
      <ImageGallery
        ref={ref}
        items={data}
        thumbnailPosition={"left"}
        showPlayButton={false}
        disableThumbnailScroll={true}
        disableKeyDown={true}
        useBrowserFullscreen={false}
        showFullscreenButton={fullScreen}
        showThumbnails={fullScreen}
        showBullets={!showNav}
        showNav={showNav}
        // onImageLoad={hideLoader}
        renderItem={renderItem}
        renderFullscreenButton={renderFullScreenButton}
        onClick={openFullScreen}
        /*onClick={() => ref.current && ref.current.toggleFullScreen()}
        renderFullscreenButton={(onClick, isFullScreen) => {
          return (
            <button type="button" className="image-gallery-icon image-gallery-fullscreen-button" aria-label="Open Fullscreen">
              {isFullScreen ? <img src={ZoomOut} alt="zoom_out" width={24} onClick={onClick}/> :
                <img src={ZoomIn} alt="zoom_in" height={24} onClick={onClick}/>}
            </button>
          )
        }}*/
      />
    </>
  );
}

export default MyGallery;
