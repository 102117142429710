import { Navigate, RouteObject } from "react-router-dom";
import RingCustomizer from "./app/pages/RingCustomizer";
import Sample from "./app/pages/Sample/Sample";
import ProductDetail from "./app/pages/ProductDetail";
import URLScreening from "./app/pages/URLScreensing";

export const MENS_PATHS = [
  'rings/profiles/:profile/widths/:width/ring_materials/:ring_materials',
  ':subpath/rings/profiles/:profile/widths/:width/ring_materials/:ring_materials',
  ':pathprefix/:subpath/rings/profiles/:profile/widths/:width/ring_materials/:ring_materials',
  ':pathprefix/:subpath/:productHandle/rings/profiles/:profile/widths/:width/ring_materials/:ring_materials'
];

export const WOMENS_PATHS = [
  'women/rings/styles/:styles/ring_materials/:ring_materials/color_arrangements/:color_arrangements/stone_quantities/:stone_quantities/variants/:variants',
  ':subpath/women/rings/styles/:styles/ring_materials/:ring_materials/color_arrangements/:color_arrangements/stone_quantities/:stone_quantities/variants/:variants',
  ':pathprefix/:subpath/women/rings/styles/:styles/ring_materials/:ring_materials/color_arrangements/:color_arrangements/stone_quantities/:stone_quantities/variants/:variants',
  ':pathprefix/:subpath/:productHandle/women/rings/styles/:styles/ring_materials/:ring_materials/color_arrangements/:color_arrangements/stone_quantities/:stone_quantities/variants/:variants'
];

export const SIGNETS_PATHS = [
  'rings/:ring_type/ring_materials/:ring_materials',
  ':subpath/rings/:ring_type/ring_materials/:ring_materials',
  ':pathprefix/:subpath/rings/:ring_type/ring_materials/:ring_materials',
  ':pathprefix/:subpath/:productHandle/rings/:ring_type/ring_materials/:ring_materials'
];


const MENS_ROUTES: RouteObject[] = MENS_PATHS.map(path => ({
  path: path,
  element: <RingCustomizer type={1} />
}));

const WOMENS_ROUTES: RouteObject[] = WOMENS_PATHS.map(path => ({
  path: path,
  element: <RingCustomizer type={2} />
}));

const SIGNET_ROUTES: RouteObject[] = SIGNETS_PATHS.map(path => ({
  path: path,
  element: <RingCustomizer type={3} />
}));

const routes: RouteObject[] = [
  {
    path: 'sample',
    element: <Sample/>
  },
  {
    path: '/',
    children: [
      ...MENS_ROUTES,
      ...WOMENS_ROUTES,
      ...SIGNET_ROUTES,
      {
        path: ':pathprefix/:subpath/shop/product/rings/profiles/:profile/widths/:width/ring_materials/:ring_materials',
        element: <ProductDetail type={1} />
      },
      {
        path: ':pathprefix/:subpath/shop/product/women/rings/styles/:styles/ring_materials/:ring_materials/color_arrangements/:color_arrangements/stone_quantities/:stone_quantities/variants/:variants',
        element: <ProductDetail type={2} />
      },
      {
        path: '',
        element: <Navigate to="apps/customize/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE" replace/>
      },
    ]
  },
  {
    path: '*',
    element: <URLScreening/>
  }
]


export default routes;
