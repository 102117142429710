import React, {FC} from 'react';
import truckIcon from '../../../assets/images/truck-icon.svg';
import handIcon from '../../../assets/images/hand-icon.svg';
import craftIcon from '../../../assets/images/craft-icon.svg';
import LineIcon from '../../../assets/images/line-icon.svg';
import LineIcon2 from '../../../assets/images/line-icon2.svg';
import "./index.scss";

const SummaryInfo = () => (
	<div className='tw-overflow-y-auto tw-overflow-x-hidden tw-h-full tw-pb-2 tw-px-4 xl:tw-px-32 info-page tw-pt-16 swatch-filter-border'>
		<div className="tw-px-4 tw-space-y-8">
			<div className="tw-space-y-3">
				<h2 className="tw-text-lg">What's Next</h2>
				<p>We're super excited to get started handcrafting your ring! Here's what you can expect now that you're ready to get started on forever.</p>
			</div>
			<div className="tw-space-y-8">
				<div className="tw-grid md:tw-grid-cols-6 tw-gap-4 xl:tw-gap-12 2xl:tw-gap-24 tw-text-xs tw-text-dark-green tw-font-medium">
					<div className="tw-flex-col tw-col-span-4">
						<div className="tw-grid tw-grid-cols-3 md:tw-gap-4 lg:tw-gap-12">
							<div className="tw-col-span-1 tw-flex tw-flex-col tw-items-center tw-justify-start">
								<img className="tw-w-10 tw-h-10" src={truckIcon}/>
								<p className="tw-mt-6 tw-text-center">Your sizing kit arrives within 2-3 days</p>
							</div>
							<div className="tw-col-span-1 tw-flex tw-flex-col tw-items-center tw-justify-start">
								<img className="tw-w-10 tw-h-10" src={handIcon}/>
								<p className="tw-mt-6 tw-text-center">Spend a day with your sizers and text us your size</p>
							</div>
							<div className="tw-col-span-1 tw-flex tw-flex-col tw-items-center tw-justify-start">
								<img className="tw-w-10 tw-h-10" src={craftIcon}/>
								<p className="tw-mt-6 tw-text-center">We handcraft your ring and guarantee it forever</p>
							</div>
						</div>
						<div className="tw-grid tw-grid-cols-2 tw-w-full tw-mt-4 info-line">
							<img className="tw-w-full" src={LineIcon}/>
							<img className="tw-w-full" src={LineIcon2}/>
						</div>
					</div>
					<div className="tw-space-y-6 tw-col-span-2 tw-flex tw-flex-col tw-items-center tw-justify-center">
						<p className="tw-font-semibold tw-text-center">Most rings arrive within 14 days of ordering.</p>
						<p className="tw-italic tw-text-center">Rushes are available at checkout, and you can text us if you're on a tight deadline
							and need to confirm delivery for your big day.</p>
					</div>
				</div>
			</div>
			<hr className="tw-opacity-30"/>
			<div className="tw-space-y-3">
				<h2 className="tw-text-lg">Shipping</h2>
				<p>All rings include free 2 day shipping. Rush orders include overnight shipping.</p>
			</div>
			<hr className="tw-opacity-30"/>
			<div className="tw-space-y-3">
				<h2 className="tw-text-lg">About Your Emblem</h2>
				<ul className="tw-list-disc tw-list-inside tw-pl-2">
					<li>Free sizing kit includes</li>
					<li>Handcrafted just for you, when you order</li>
					<li>Guaranteed for life</li>
					<li>Free returns and exchanges within 30 days</li>
					<li>One free size exchange within 30 days</li>
				</ul>
			</div>
			<hr className="tw-opacity-30"/>
			<div className="tw-space-y-3">
				<h2 className="tw-text-lg">Need Help?</h2>
				<p>Text us anytime for help designing, info about materials, etc.</p>
				<p><a href="tel:tw-8013164481">(801) 316-4481</a></p>
			</div>
		</div>
	</div>
);

export default SummaryInfo;
