import React from 'react';
import { Link } from "react-router-dom";
import './index.scss';

const UnSubscribed = () => {
  return (
    <div className='unsubscribed'>
      <h2 className='primary-font-family tw-text-lg sm:tw-text-2xl md:tw-text-3xl tw-text-center'>Your subscription seems inactive<br/>Please contact administrator for activation</h2>
    </div>
  );
}

export default UnSubscribed;