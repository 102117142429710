import React, { useState } from 'react';
import logo from '../../../logo.svg';
import './index.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Counter } from '../../components/counter/Counter';

function Sample() {
  const queryClient = useQueryClient();
  const [value, setValue] = useState<string>('')
  /**
   * Ref: https://codesandbox.io/s/late-shadow-4wexms
   * for todo sample app. Inorder to work api run the sandbox.
   */
  const { status, data, isFetching, isSuccess, isError, isLoading } = useQuery('todos', () => 
    fetch('https://97sv4y.sse.codesandbox.io/api/data').then(res => res.json()))

  const mutation = useMutation((value: string) => fetch(`https://97sv4y.sse.codesandbox.io/api/data?add=${value}`).then(res => res.json()), 
  {
    onSuccess: () => {
      setValue('')
      queryClient.invalidateQueries('todos')
    }
  })
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
        <ol>
          {data && data.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
        <input type="text" value={value} onChange={ev => setValue(ev.target.value)}/><br/>

        <button onClick={() => {mutation.mutate(value)}}>Add</button>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header>
    </div>
  );
}

export default Sample;
