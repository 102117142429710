import { useRoutes } from 'react-router-dom';
import routes from './routes';

const App = (props: any) => {
  const element = useRoutes(routes)
  return (
    <>{element}</>
  )
}

export default App;
