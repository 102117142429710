import React from 'react';
import PersonalizedPatternsCitySkyline from '../../../assets/images/Personalized_Patterns_City_Skyline.png';
import PersonalizedPatternsSoundwave from '../../../assets/images/Personalized_Patterns_Soundwave.png';

const PersonalizedMachinePatternInfo = () => (
    <div className="tw-px-6">
        <div className="tw-max-h-[550px] tw-overflow-auto">
            <div className="tw-py-4">
                <div className="tw-text-xl tw-mb-4 tw-font-bold">Personalized Patterns</div>
                <p className="tw-text-gray-700 tw-text-sm tw-leading-6">
                    <span className="tw-font-bold tw-text-lg">Every pattern in this collection is personalized for you! </span>This means that the fingerprint can be from any loved one, a skyline of any city, anyone’s handwriting, a sound wave of anything, and any roman numeral date.
                </p>
                <p className="tw-text-gray-700 tw-text-sm tw-leading-6 tw-mt-3">
                    <span className="tw-font-bold tw-text-lg">After you place your order, our team will contact you to get the necessary info for personalization.</span>
                </p>
            </div>
            <div className="tw-pb-2">
                <img src={PersonalizedPatternsCitySkyline} alt="pattern-cityskyline" />
            </div>
            <div className="tw-pb-2">
                <img src={PersonalizedPatternsSoundwave} alt="pattern-soundwave" />
            </div>
        </div>
    </div>
);

export default PersonalizedMachinePatternInfo;
