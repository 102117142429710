import React, { useEffect, useState } from 'react';
import { IOption, ISwatchContent } from '../../types/customizer';
import SwatchOption from '../SwatchOption';

export interface INoneFilterProps {
  content: ISwatchContent;
  onChange: (updated: any) => void;
  innerTab?: number;
  isFetching: boolean;
}

const EngravingNoneTabFilter = ({ content, onChange, isFetching, innerTab = 1 }:INoneFilterProps) => {

  const options: Array<IOption> = content.options as Array<IOption>;
  const { hasSwatch, fieldType, selectedOption } = content;
  const [isChanging, setIsChanging] = useState('');

  useEffect(() => {
    if (!isFetching) {
      setIsChanging('');
    }
  }, [isFetching]);

  const handleChange = (updatedObj: Record<string, string>) => {
    setIsChanging(Object.values(updatedObj)[0]); // used for deseclecting old one till response recieved
    onChange(updatedObj);
  };

  return (
    <div className="tw-flex tw-flex-col tw-pb-2 tw-items-center tw-h-full swatch-filter-border">
      <div className="lg:tw-pt-8 xs:tw-pt-2 sm:tw-pt-4 md: tw-pt-6 tw-text-center lg:tw-text-base md:tw-text-lg xs:tw-text-base tw-w-4/6">
        <p>Add an engraving to the inside of your ring</p>
      </div>
      <div className="tw-flex tw-flex-wrap tw-justify-center tw-overflow-auto">
        {options.map((option: IOption, index: number) => (
          <SwatchOption
            key={index}
            {...option}
            hasSwatch={hasSwatch}
            fieldType={fieldType}
            onChange={handleChange}
            selectedOption={selectedOption}
            isChanging={isChanging}
          />
        ))}
      </div>
    </div>
  );
};

export default EngravingNoneTabFilter;

