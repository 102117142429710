import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from "../../types/twMerge";
import { FONT_MAP, FONT_SIZE_MAP } from '../../../utils/commonRingUtils';
import { IOption, ISwatchContent } from '../../types/customizer';
import If from '../If';
import SwatchOption from '../SwatchOption';

export interface IEngravingFilterProps {
  content: ISwatchContent;
  onChange: (updated: any) => void;
  innerTab?: number;
  isFetching: boolean;
}

const EngravingFilter = ({ content, onChange, isFetching, innerTab = 1 }: IEngravingFilterProps) => {

  const options: Array<IOption> = content.options as Array<IOption>;
  const { hasSwatch, fieldType, selectedOption, engravingText } = content;
  const [isChanging, setIsChanging] = useState('');
  const [font, setFont] = useState<IOption>();
  const [text, setText] = useState('');

  const timeOutId = useRef(0);

  useEffect(() => {
    if (!isFetching) {
      setIsChanging('');
    }
  }, [isFetching]);

  useEffect(() => {
  /**
   * For Engraving tab, set selectedOption and text in state to manage local state change
   */
    setText(engravingText || '');
    setFont(selectedOption);
  }, [engravingText, selectedOption]);

  const handleFontChange = (updatedObj: Record<string, string>) => {
    const sku = Object.values(updatedObj)[0];
    const selectedFont = options.find(item => item.type === 'option' && item.sku === sku);
    if (selectedFont?.type === 'option') {
      if (!font || selectedFont?.sku !== font.sku) {
        setFont(selectedFont);
      }
      handleEngravingUpdate(selectedFont, selectedFont.sku === 'NONE' ? '' : text);
    }
  };

  const handleTextChange = (e: any) => {
    if (isFetching) {
      return;
    }
    const { value } = e.target;
    if (value.length <= 35) {
      setText(value);
      handleEngravingUpdate(font, value);
    }
  };

  const handleFontNameChange = (e: any) => {
    const { value } = e.target;
    if (font) {
      const updatedFont = {
        ...font,
        name: value,
      };
      setFont(updatedFont);
      handleEngravingUpdate(updatedFont, text);
    }
  };

  const handleEngravingUpdate = (font: IOption | undefined, text: string) => {
    clearTimeout(timeOutId.current);
    timeOutId.current = window.setTimeout(() => {
      const engraving_new = `I_${font?.sku}-${font?.name}_${text}`;
      /**
    * Check font name is availble to check custom font is availble
    * then check if none then allow to update
    * Else text is available then allows to update
    */
      if (font?.name && (font?.sku === 'NONE' || text)) {
        onChange({ engraving_new });
      }
    }, 1000);
  };

  return (
    <div className="tw-flex tw-justify-center tw-pb-2" style={{ height: `calc(100% - ${45 * innerTab}px)` }}>
      <div className="tw-w-full sm:tw-w-full tw-px-4">
        <div className="tw-overflow-y-auto tw-overflow-x-hidden tw-h-full tw-pb-2">
          <div className="tw-flex tw-flex-wrap tw-overflow-auto">
            {options.map((option: IOption, index: number) => (
              <SwatchOption
                key={index}
                {...option}
                selected={option.sku === font?.sku}
                hasSwatch={hasSwatch}
                fieldType={fieldType}
                onChange={handleFontChange}
                selectedOption={selectedOption}
                isChanging={isChanging}
              />
            ))}
          </div>
          <If condition={font && font.sku !== 'NONE'}>
            <If condition={font?.sku === 'CUSTOM'}>
              <div className="tw-mt-10 tw-flex tw-justify-between tw-mr-3">
                <div className="tw-text-lg primary-font-family tw-text-primary-color tw-pt-3">Font Name</div>
                <div className="tw-text-right tw-w-4/6">
                  <input
                    type="text"
                    value={font?.name || ''}
                    onChange={handleFontNameChange}
                    placeholder="(none)"
                    className={clsx({
                      'tw-px-4 tw-pb-1 tw-pt-3 tw-w-full tw-text-lg tw-text-black tw-text-right tw-border-b tw-border-solid tw-border-gray-400 tw-outline-none': true,
                      'tw-border-red': font?.name.length === 0
                    })}
                  />
                </div>
              </div>
            </If>
            <div className="tw-mt-10 tw-flex tw-justify-between tw-mr-3">
              <div className="tw-text-lg primary-font-family tw-text-primary-color tw-pt-3">Text</div>
              <div className="tw-text-right tw-w-4/6">
                <input
                  type="text"
                  value={text}
                  onChange={handleTextChange}
                  placeholder="(none)"
                  className={twMerge(clsx({
                    'tw-px-4 tw-pb-1 tw-pt-3 tw-w-full tw-text-black tw-text-right tw-border-b tw-border-solid tw-border-gray-400 tw-outline-none': true,
                    'tw-border-red': text.length === 0
                  },
                  `${FONT_MAP[font?.name || '']}`,
                  `${FONT_SIZE_MAP[font?.name || '']}`
                  ))}
                />
                <div className="tw-text-sm tw-text-cyan tw-font-normal tw-mt-2">{(35 - text.length)} characters left</div>
                <div className="tw-text-sm tw-text-cyan tw-font-normal tw-mt-2">*Note: Engraving text will not appear on the ring image.</div>
              </div>
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

export default EngravingFilter;

